import { BASE_RULES_KEY, type RuleResponse, type RuleArray } from '@core/types'
import { inject } from '#imports'

export const useRules = () => {
    if (import.meta.client) {
        const { translations: { validationRules: validationMessage } } = useCoreConfig()

        const baseRules = inject(BASE_RULES_KEY, undefined)

        if (!baseRules) {
            throw new Error('Rules not provided!')
        }

        const {
            required,
            minLength,
            noNumbers,
            noSpecialCharacters,
            EMAIL,
            EXISTING_PASSWORD,
            NEW_PASSWORD
        } = baseRules

        function phone(value?: string): RuleResponse {
            if (!value) return true

            const strippedNo = value.replace('+45', '').replace(/\s/g, '')

            return strippedNo?.length === 8 || validationMessage.phone.invalid
        }

        function ssn(value?: string): RuleResponse {
            if (!value) return true

            if (value.length < 11) return validationMessage.ssn?.invalid as string

            return true
        }

        const PHONE: RuleArray = [
            phone,
        ]

        const FULLNAME: RuleArray = [
            required(validationMessage.required.fullName),
            minLength(2, validationMessage.minLength.name),
            noNumbers,
            noSpecialCharacters,
        ]

        const SSN: RuleArray = [
            required(validationMessage.required.ssn),
            ssn,
        ]

        return {
            required,
            PHONE,
            FULLNAME,
            EMAIL,
            EXISTING_PASSWORD,
            NEW_PASSWORD,
            SSN,
        }
    }
    else {
        return {
            required: () => {},
            PHONE: [],
            FULLNAME: [],
            EMAIL: [],
            EXISTING_PASSWORD: [],
            NEW_PASSWORD: [],
            SSN: [],
        }
    }
}
